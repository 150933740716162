$(document).ready(function(){
	$(window).load(function(){

		AOS.init();

		if($('.home ').is(':visible')){

		var scene1 = document.getElementById('scene1');
		var parallaxInstance = new Parallax(scene1);

		var scene2 = document.getElementById('scene2');
		var parallaxInstance = new Parallax(scene2);

		var scene3 = document.getElementById('scene3');
		var parallaxInstance = new Parallax(scene3);

		}

		$('#button-next').click(function(){
		    $("#pagebanner").slick('slickNext');
		});
		$('#button-prev').click(function(){
		    $("#pagebanner").slick('slickPrev');
		});

		$('#slider-next').click(function(){
		    $("#slide-content").slick('slickNext');
		    
		});
		$('#slider-prev').click(function(){
		    $("#slide-content").slick('slickPrev');
		   
		});
		
		$(".dropdown").on('click', function(event) {
	      // Make sure this.hash has a value before overriding default behavior
	      if (this.hash !== "") {
	        // Prevent default anchor click behavior
	        event.preventDefault();
	        // Store hash
	        var hash = this.hash;
	        // Using jQuery's animate() method to add smooth page scroll
	        // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
	        $('html, body').animate({
	          scrollTop: $(hash).offset().top - 200
	        }, 800, function(){
	          // Add hash (#) to URL when done scrolling (default click behavior)
	          window.location.hash = hash;
	          // $('.down').hide('slow');
	        });
	      } // End if
	    });

	    var locationValue = (new URL(location.href)).searchParams.get('a');


				// Global Variables
		var $pageHeight = $(window).height(),
		$pageWidth = $(window).width(),
		$navHeight = $('header.site-header').outerHeight(),
		$quickLinks = $('nav.site-quicklinks').outerHeight(),
		$footerHeight = $('footer.footer').outerHeight();

		
		$('.main-panel').css({
			'min-height': ( $pageHeight + 2 ) - $navHeight,
			'margin-bottom' : $quickLinks,
			'margin-top': $navHeight - 36,
			'padding-bottom': $footerHeight
		});

		$('.back-to-top').hide();
		
		$('.back-to-top').css({
			'bottom' : $quickLinks + 35
		});

		$(window).scroll(function () {
			if ($(this).scrollTop() > 100) {
				$('.back-to-top').fadeIn();
			} else {
				$('.back-to-top').fadeOut();
			}
		});
		$('.back-to-top a').click(function () {
			$('body,html').animate({
				scrollTop: 0
			}, 800);
			return false;
		});

		$('.loader-overlay').fadeOut(200);

		$('.mailto-container .mailto-contain, .career-container .career-contain').css({
			'padding-bottom' : $quickLinks,
			'padding-top' : $navHeight
		});


		// Sub Menus
		$('.menu-container .menu-contain nav ul li.menu-item-has-children').each(function(){
			$(this).append('<button type="button" class="submenu-button"><i class="fas fa-angle-right"></i></button>');
		});
		$('.menu-container .menu-contain nav ul li .sub-menu').each(function(){
			$(this).prepend('<li><button type="button" class="submenu-close-button"><i class="fas fa-angle-left"></i>Back</button></li>');
		});


		 $(document).on('click', '.play-video', function(e) {
		    e.preventDefault();

		    if ($(this).siblings()[0].paused){
		      $(this).fadeOut();
		      //$(".btn").css('display','none');
		      
		      $(this).siblings()[0].play();
		      $(this).parent().addClass('played');
		    }
		    else{
		      $('video')[0].pause();
		    }
		  });

		  $(document).on('click', '.pause-video', function(e) {
		    e.preventDefault();
		    if ($(this).siblings()[0].paused){
		      
		      $(this).siblings()[0].play();
		      
		    }
		    else{
		     $(this).siblings()[0].pause();
		      $('.vid').removeClass('played');
		      $('.play-video').fadeIn();
		    }
		  });

	});
});