'use strict';

$(document).ready(function () {
		$(window).load(function () {

				AOS.init();

				if ($('.home ').is(':visible')) {

						var scene1 = document.getElementById('scene1');
						var parallaxInstance = new Parallax(scene1);

						var scene2 = document.getElementById('scene2');
						var parallaxInstance = new Parallax(scene2);

						var scene3 = document.getElementById('scene3');
						var parallaxInstance = new Parallax(scene3);
				}

				$('#button-next').click(function () {
						$("#pagebanner").slick('slickNext');
				});
				$('#button-prev').click(function () {
						$("#pagebanner").slick('slickPrev');
				});

				$('#slider-next').click(function () {
						$("#slide-content").slick('slickNext');
				});
				$('#slider-prev').click(function () {
						$("#slide-content").slick('slickPrev');
				});

				$(".dropdown").on('click', function (event) {
						// Make sure this.hash has a value before overriding default behavior
						if (this.hash !== "") {
								// Prevent default anchor click behavior
								event.preventDefault();
								// Store hash
								var hash = this.hash;
								// Using jQuery's animate() method to add smooth page scroll
								// The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
								$('html, body').animate({
										scrollTop: $(hash).offset().top - 200
								}, 800, function () {
										// Add hash (#) to URL when done scrolling (default click behavior)
										window.location.hash = hash;
										// $('.down').hide('slow');
								});
						} // End if
				});

				var locationValue = new URL(location.href).searchParams.get('a');

				// Global Variables
				var $pageHeight = $(window).height(),
				    $pageWidth = $(window).width(),
				    $navHeight = $('header.site-header').outerHeight(),
				    $quickLinks = $('nav.site-quicklinks').outerHeight(),
				    $footerHeight = $('footer.footer').outerHeight();

				$('.main-panel').css({
						'min-height': $pageHeight + 2 - $navHeight,
						'margin-bottom': $quickLinks,
						'margin-top': $navHeight - 36,
						'padding-bottom': $footerHeight
				});

				$('.back-to-top').hide();

				$('.back-to-top').css({
						'bottom': $quickLinks + 35
				});

				$(window).scroll(function () {
						if ($(this).scrollTop() > 100) {
								$('.back-to-top').fadeIn();
						} else {
								$('.back-to-top').fadeOut();
						}
				});
				$('.back-to-top a').click(function () {
						$('body,html').animate({
								scrollTop: 0
						}, 800);
						return false;
				});

				$('.loader-overlay').fadeOut(200);

				$('.mailto-container .mailto-contain, .career-container .career-contain').css({
						'padding-bottom': $quickLinks,
						'padding-top': $navHeight
				});

				// Sub Menus
				$('.menu-container .menu-contain nav ul li.menu-item-has-children').each(function () {
						$(this).append('<button type="button" class="submenu-button"><i class="fas fa-angle-right"></i></button>');
				});
				$('.menu-container .menu-contain nav ul li .sub-menu').each(function () {
						$(this).prepend('<li><button type="button" class="submenu-close-button"><i class="fas fa-angle-left"></i>Back</button></li>');
				});

				$(document).on('click', '.play-video', function (e) {
						e.preventDefault();

						if ($(this).siblings()[0].paused) {
								$(this).fadeOut();
								//$(".btn").css('display','none');

								$(this).siblings()[0].play();
								$(this).parent().addClass('played');
						} else {
								$('video')[0].pause();
						}
				});

				$(document).on('click', '.pause-video', function (e) {
						e.preventDefault();
						if ($(this).siblings()[0].paused) {

								$(this).siblings()[0].play();
						} else {
								$(this).siblings()[0].pause();
								$('.vid').removeClass('played');
								$('.play-video').fadeIn();
						}
				});
		});
});
'use strict';

$(document).ready(function () {
	$(window).load(function () {

		// Global Variables
		var $pageHeight = $(window).height(),
		    $pageWidth = $(window).width(),
		    $navHeight = $('header.site-header').outerHeight(),
		    $quickLinks = $('nav.site-quicklinks').outerHeight(),
		    $footerHeight = $('footer.site-footer').outerHeight();

		iOS_CaretBug();
		responsiveSlick();
		cf7formsubmit();
		tableClass();
		stickySideBar();

		$(window).on('resize', function () {});
	});
});

// IOS INPUT BUG FIX
function iOS_CaretBug() {
	var ua = navigator.userAgent,
	    scrollTopPosition,
	    iOS = /iPad|iPhone|iPod/.test(ua),
	    iOS11 = /OS 11_0|OS 11_1|OS 11_2/.test(ua);

	// ios 11 bug caret position
	if (iOS && iOS11) {

		$(document.body).on('show.bs.modal', function (e) {
			if ($(e.target).hasClass('modal')) {
				// Get scroll position before moving top
				scrollTopPosition = $(document).scrollTop();

				// Add CSS to body "position: fixed"
				$("body").addClass("iosBugFixCaret");
			}
		});

		$(document.body).on('hide.bs.modal', function (e) {
			if ($(e.target).hasClass('modal')) {
				// Remove CSS to body "position: fixed"
				$("body").removeClass("iosBugFixCaret");

				//Go back to initial position in document
				$(document).scrollTop(scrollTopPosition);
			}
		});
	}
}

function tableClass() {
	var $tables = $(document).find('table');
	if ($tables) {
		$tables.wrap('<div class="table-responsive"></div>');
		$tables.addClass('table');
	}
}

function smoothScrollTo(anchor) {
	var duration = 2000; //time (milliseconds) it takes to reach anchor point
	var targetY = $(anchor).offset().top;

	$("html, body").animate({
		"scrollTop": targetY
	}, duration, 'easeInOutCubic');
}

// Plugins
function objLightcase() {
	$('a[data-rel^=lightcase]').lightcase();
}

function responsiveSlick() {
	$('.slick-responsive').each(function () {
		var breaks = $(this).data('breakpoints').split('|'),
		    breakArr = [],
		    arrowsval;
		$.each(breaks, function (i, items) {
			breakArr.push({
				breakpoint: parseInt(items.split(',')[0]),
				settings: {
					slidesToShow: parseInt(items.split(',')[1])
				}
			});
		});
		if ($(this).data('arrows')) {
			arrowsval = true;
		} else {
			arrowsval = false;
		}
		$(this).slick({
			dots: false,
			infinite: true,
			speed: 300,
			autoplay: true,
			arrows: arrowsval,
			slidesToShow: $(this).data('defaultview'),
			slidesToScroll: 1,
			adaptiveHeight: true,
			asNavFor: $(this).data('thumb'),
			responsive: breakArr,
			prevArrow: '<button type="button" class="slick-prev slick-controller fa fa-angle-left"></button>',
			nextArrow: '<button type="button" class="slick-next slick-controller fa fa-angle-right"></button>'
		});
	});
}

function cf7formsubmit() {

	$('.wpcf7-form').each(function () {
		var form = $(this);
		form.find(".wpcf7-submit, .ajax-loader").wrapAll('<div class="wcf7-wrap-btn"></div>');
		setTimeout(function () {
			var wdSubmit = form.find(".wpcf7-submit").outerWidth();
			form.find('.wcf7-wrap-btn').css({
				'width': wdSubmit
			});
		}, 100);
	});

	$(document).on('click', '.wpcf7-submit', function () {
		var $btn = $(this);
		var $ajaxLoader = $('.ajax-loader');

		$btn.addClass('loading');
		$ajaxLoader.addClass('visible');

		var sitelink = $('.usd').data('usdacct');
		document.addEventListener('wpcf7mailsent', function (event) {
			location = sitelink + '/thank-you/';
		}, false);
		document.addEventListener('wpcf7invalid', function (event) {
			$btn.removeClass('loading');
			$ajaxLoader.removeClass('visible');
		}, false);
	});
}

function carouselAnimation() {
	/* Demo Scripts for Bootstrap Carousel and Animate.css article
 * on SitePoint by Maria Antonietta Perna
 */
	//Function to animate slider captions 
	function doAnimations(elems) {
		//Cache the animationend event in a variable
		var animEndEv = 'webkitAnimationEnd animationend';

		elems.each(function () {
			var $this = $(this),
			    $animationType = $this.data('animation');
			$this.addClass($animationType).one(animEndEv, function () {
				$this.removeClass($animationType);
			});
		});
	}

	//Variables on page load 
	var $myCarousel = $('.carousel'),
	    $firstAnimatingElems = $myCarousel.find('.item:first').find("[data-animation ^= 'animated']");

	//Initialize carousel 
	$myCarousel.carousel();

	//Animate captions in first slide on page load 
	doAnimations($firstAnimatingElems);

	//Pause carousel  
	$myCarousel.carousel('pause');

	//Other slides to be animated on carousel slide event 
	$myCarousel.on('slide.bs.carousel', function (e) {
		var $animatingElems = $(e.relatedTarget).find("[data-animation ^= 'animated']");
		doAnimations($animatingElems);
	});

	$('.carousel').carousel();
}

/* MODERNIZR LAYOUT - This serves as the the media query inside the Javascript */
function screenBpoints() {
	if (Modernizr.mq('(min-width: 1200px)')) {} else if (Modernizr.mq('(min-width: 992px)') && Modernizr.mq('(max-width: 1199px)')) {} else if (Modernizr.mq('(max-width: 991px)') && Modernizr.mq('(min-width: 768px)')) {} else {}
}

function stickySideBar() {
	var $navHeight = $('header.site-header').outerHeight();
	if (Modernizr.mq('(min-width: 768px)')) {
		$('.sticky-bar').each(function () {
			$(this).stickySidebar({
				topSpacing: $navHeight + 30
			});
		});
	}
}
'use strict';

$(document).ready(function () {
	$(window).load(function () {

		// Global Variables
		var $pageHeight = $(window).height(),
		    $pageWidth = $(window).width(),
		    $navHeight = $('header.site-header').outerHeight(),
		    $quickLinks = $('nav.site-quicklinks').outerHeight(),
		    $footerHeight = $('footer.site-footer').outerHeight();

		$(document).on('click', '.mobile-menu.notAnimated', function () {
			$('body, html').css({
				'overflow': 'hidden',
				'padding-right': '8.75px'
			});
			$('header.site-header.push-top').css({
				'padding-right': '17.5px'
			});
			$(this).removeClass('notAnimated').addClass('animated');
			$('.menu-container').addClass('show');
			setTimeout(function () {
				$('.menu-container').addClass('animate');
				$('.menu-container .menu-contain').css({
					'padding-bottom': $quickLinks,
					'padding-top': $navHeight
				});
			}, 500);
		});

		$(document).on('click', '.mobile-menu.animated', function () {
			$(this).removeClass('animated').addClass('notAnimated');

			$('.menu-container .menu-contain').css({
				'padding-bottom': 0,
				'padding-top': 0
			});

			$('.menu-container').removeClass('animate');
			$('body, html').removeAttr('style');

			setTimeout(function () {
				$('.menu-container').removeClass('show');
			}, 500);

			setTimeout(function () {
				$('.menu-container .menu-contain nav ul li .sub-menu').removeClass('show');
			}, 700);
		});

		$(document).on('click', '.submenu-button', function () {
			$(this).parent('li').find(' > .sub-menu').addClass('show');
		});
		$(document).on('click', '.submenu-close-button', function () {
			$(this).parent('li').parent('ul').removeClass('show');
		});

		$(document).on('click', 'a[href^="mailto:"]', function (e) {
			e.preventDefault();
			$('body, html').css({
				'overflow': 'hidden',
				'padding-right': '8.75px'
			});

			$('header.site-header.push-top').css({
				'padding-right': '17.5px'
			});

			$('.mailto-container').addClass('show');
			setTimeout(function () {
				$('.mailto-container').addClass('animate');
			}, 300);
		});

		$(document).on('click', '.mailto-close-button', function () {
			$('.mailto-container').removeClass('animate');
			$('body, html').removeAttr('style');

			setTimeout(function () {
				$('.mailto-container').removeClass('show');
			}, 300);
		});

		$(document).on('click', '.search-btn', function () {
			$('body, html').css({
				'overflow': 'hidden'
			});
			$('.search-form-template').addClass('show');
			setTimeout(function () {
				$('.search-form-template').addClass('animate');
			}, 300);
		});

		$(document).on('click', '.search-close-button', function () {
			$('body, html').removeAttr('style');
			$('.search-form-template').removeClass('show');
			setTimeout(function () {
				$('.search-form-template').removeClass('animate');
			}, 300);
		});

		$(document).on('click', '.testimonial-item-contain', function () {
			$.ajax({
				url: $('.ajaxlocation').data('ajaxlocation'),
				type: 'POST',
				dataType: 'json',
				data: {
					action: 'request_testimonial',
					id: $(this).data('id')
				},
				beforeSend: function beforeSend() {
					$('body, html').css({
						'overflow': 'hidden',
						'padding-right': '8.75px'
					});
					$('.popup-modal-container').addClass('show');
					$('html').addClass('popupshow');
				},
				success: function success(response) {

					setTimeout(function () {
						$('.popup-modal-container').addClass('animate');
					}, 300);
					$('.popup-modal-container .popup-body').html(response.content);
				}
			});
		});

		$(document).on('click', '.popup-close', function () {
			$('body, html').removeAttr('style');
			$('header.site-header.push-top').removeAttr('style');
			$('.popup-modal-container').removeClass('show');
			$('html').removeClass('popupshow');
			setTimeout(function () {
				$('.popup-modal-container').removeClass('animate');
			}, 300);
		});

		$(document).on('click', '.apply-btn', function (e) {
			var position = $(this).data('position');
			e.preventDefault();
			$('body, html').css({
				'overflow': 'hidden',
				'padding-right': '8.75px'
			});

			$('header.site-header.push-top').css({
				'padding-right': '17.5px'
			});

			$('.career-container .yourPosition input').val(position);
			$('.career-container').addClass('show');
			setTimeout(function () {
				$('.career-container').addClass('animate');
			}, 300);
		});

		$(document).on('click', '.career-close-button', function () {
			$('.career-container').removeClass('animate');
			$('body, html').removeAttr('style');

			setTimeout(function () {
				$('.career-container').removeClass('show');
			}, 300);
		});

		$(document).on('click', '#resumeBtn', function () {
			var input = $('.resume-container .resume input');
			input.click();
		});

		$('.resume-container .resume input').change(function (e) {
			var FileName = e.target.files[0];
			$('#resumeBtn').addClass('active').text(FileName.name);
		});

		$(document).on('click', '.expand-term', function (e) {
			e.preventDefault();
			$(this).toggleClass('fa-angle-down').toggleClass('fa-angle-up');
			$(this).parent('a').parent('.parent-term').find('.chid-term').first().toggleClass('active');
		});

		$(document).on('click', '.expand-selection-term', function (e) {
			$('.archive-sidebar .term-container').toggleClass('active');
		});
	});
});
'use strict';

$(document).ready(function () {
	$(window).load(function () {

		// Global Variables
		var $pageHeight = $(window).height(),
		    $pageWidth = $(window).width(),
		    $navHeight = $('header.site-header').outerHeight(),
		    $quickLinks = $('nav.site-quicklinks').outerHeight(),
		    $footerHeight = $('footer.site-footer').outerHeight();
	});
});